import React from 'react';
import "./footer.css";

const Footer = () => {

  return (
    <div className="footer-wrapper">
      <footer className='footer'>
        Built with Gatsby,<br/>© Copyright 2024 Tae Geun, Kim
      </footer>
    </div>
  )
}

export default Footer
